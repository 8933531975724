import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../layouts/Layout'
import heroImageMap from '../../components/MapSection/LargeMap.png'
import Hero from '../../components/Hero/Hero'
import auther from '../../helpers/auth'
import { hero } from './login.module.scss'

const auth = auther()

const Logout = () => {
  React.useEffect(() => {
    auth.logout()
  }, [])
  return (
    <Layout getInTouchVisible={false} blueFooter={true} login={true}>
      <Helmet>
        <title>Logout - Emso</title>
      </Helmet>
      <Hero header={'Logging Out'} className={hero} image={heroImageMap} content={[
        'Please wait...',
      ]}>
      </Hero>
    </Layout>
  )
}

export default Logout;